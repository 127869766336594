import React, {useContext, useState} from 'react';
import {Header, Form, Button, Dropdown, Icon} from 'semantic-ui-react';
import usernames from '../usernames.json';
import { useNavigate } from 'react-router-dom';
import UserContext from "../contexts/UserContext";
import NotificationContext from "../contexts/NotificationContext";

const LoginPage = () => {
  // TODO Display Failed auth
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);

  const { user, login, logout } = useContext(UserContext);
  const { setNotification } = useContext(NotificationContext)

  const usernameOptions = usernames.map((username, i) => ({
    key: i + 1,
    value: username,
    text: username,
  }));

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await login({username, password})
      console.log('Logged in user:', user);
      setIncorrectCredentials(false)
      setNotification("Login successful")
      navigate('/');
    } catch (error) {
      setNotification("Authentication Failed", "warning")
      setIncorrectCredentials(true)
      console.error('Error logging in:', error);
    }
  };

  const handleLogout = () => {
    logout()
    navigate('/');
  };

  const changeUsername = (e, { value }) => {
    setUsername(value)
    setIncorrectCredentials(false)
  }

  const changePassword = (e) => {
    setPassword(e.target.value)
    setIncorrectCredentials(false)
  }

  return (
    <div>
      <Header as="h1">
        Login to Ravelston Rise Residents' Association
      </Header>
      {!user ? (
        <Form onSubmit={handleLogin}>
          <Form.Field>
            <label>Username</label>
            <Dropdown
              placeholder="Select your username"
              fluid
              selection
              options={usernameOptions}
              onChange={changeUsername}
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <Form.Group inline>
              <Form.Input
                type={showPasswords ? "text" : "password" }
                placeholder="Enter your password"
                onChange={changePassword}
                error={incorrectCredentials ? {content: "Incorrect credentials"} : null}
                width={15}
              />
              <Icon onClick={() => setShowPasswords(!showPasswords)} width={1} size='large' name={showPasswords ? 'eye slash' : 'eye'} />
            </Form.Group>
          </Form.Field>
          <Button type="submit" primary>
            Login
          </Button>
          <p>If you have forgotten your password please contact a member of the council.</p>
        </Form>
      ) : (
        <div>
          <p>Logged in as {user.username}</p>
          <Button onClick={handleLogout} primary>
            Log Out
          </Button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
