import React, {useEffect, useState} from 'react';
import {Button, Header} from 'semantic-ui-react';
import ThreadList from '../components/ThreadList'
import forumService from "../services/forumService";
import NewThread from "../components/NewThread";

const ForumPage = () => {

    const [threads, setThreads] = useState([]);
    const [nextPaginationToken, setNextPaginationToken] = useState(null)
    const [paginationTokenStack, setPaginationTokenStack] = useState([])
    const [newThread, setNewThread] = useState(null)
    const [loading, setLoading] = useState(true);
    const forumId = 'forum0'


    useEffect(() => {
        console.log("Getting threads")
        const refreshThreads = async () => {
            const threadsResponse = await forumService.getThreads({forumId})
            setNextPaginationToken(threadsResponse.nextPaginationToken)
            setThreads(threadsResponse.threads);
            setLoading(false);
        }
        void refreshThreads()
    }, [newThread]);

    const nextPage = async () => {
        setLoading(true)
        const threadsResponse = await forumService.getThreads({forumId, nextPaginationToken})
        setPaginationTokenStack([
            ...paginationTokenStack,
            threadsResponse.nextPaginationToken
        ])
        setNextPaginationToken(threadsResponse.nextPaginationToken)
        setThreads(threadsResponse.threads);
        setLoading(false);
    }
    const previousPage = async () => {
        setLoading(true)
        const threadsResponse = await forumService.getThreads({
            forumId,
            nextPaginationToken: paginationTokenStack[paginationTokenStack.length - 1]
        })
        setPaginationTokenStack(paginationTokenStack.slice(0, -1))
        setNextPaginationToken(threadsResponse.nextPaginationToken)
        setThreads(threadsResponse.threads);
        setLoading(false);
    }

    const threadList = (
        <div>
            <ThreadList forumId={forumId} threads={threads} setNewThread={setNewThread}/>
            <Button disabled={paginationTokenStack.length < 1} basic style={{margin: "1em 0 1em 0"}} onClick={previousPage}><i className="angle double left icon"></i>Newer</Button>
            <Button disabled={!nextPaginationToken} basic style={{margin: "1em 0 1em 0"}} onClick={nextPage}>Older<i className="angle double right icon"></i></Button>
            <NewThread forumId={forumId} setNewThread={setNewThread} />

        </div>
    )
    const content = loading ? (<div>Loading...</div>) : threadList

    return (
        <div>
            <Header as="h1">Forum</Header>

            {content}
        </div>
    );
};

export default ForumPage;
