import React, {useEffect, useState} from 'react';
import {Header} from 'semantic-ui-react';
import forumService from "../services/forumService";
import {useParams} from "react-router-dom";
import ThreadDetail from "../components/ThreadDetail";

const ThreadPage = () => {

    const [replies, setReplies] = useState([]);
    const [thread, setThread] = useState(null)
    const [newReply, setNewReply] = useState(null)
    const [loading, setLoading] = useState(true);
    const {threadId} = useParams()
    const forumId = 'forum0'

    useEffect(() => {
        const refreshReplies = async () => {
            const {thread, replies} = await forumService.getThreadReplies({forumId, threadId})
            setThread(thread)
            setReplies(replies);
            setLoading(false);
        }

        void refreshReplies()
    }, [newReply, threadId]);

    const content = loading ? (<div>Loading...</div>) : <ThreadDetail forumId={forumId} thread={thread} replies={replies} setNewReply={setNewReply}/>

    return (
        <div>
            <Header as="h1">Forum</Header>
            {content}
        </div>
    );
};

export default ThreadPage;
