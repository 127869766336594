import React, {useContext, useEffect, useState} from 'react'
import {Button, Container, Divider, Header, Icon, List, Message, Modal} from 'semantic-ui-react'
import {Auth, Storage} from 'aws-amplify';
import UserContext from "../contexts/UserContext";

const DocumentsPage = () => {
    //TODO actually get docs
    //TODO upload docs for admins
    const [files, setFiles] = useState([]);
    const [uploadFile, setUploadFile] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [deleteFileKey, setDeleteFileKey] = useState('')
    const {isAdmin} = useContext(UserContext);

    useEffect(() => {
        async function fetchFiles() {
            try {
                const currentUser = await Auth.currentAuthenticatedUser()
                console.log("Logged in user:", currentUser)
                setFiles((await Storage.list('')).results);
            } catch (error) {
                console.error("Error fetching files:", error);
            }
        }

        void fetchFiles();
    }, []);

    const fileSelected = (event) => {
        setUploadFile(event.target.files[0])
    }

    const upload = async () => {
        await Storage.put(uploadFile.name, uploadFile);
        setUploadFile(null)
        // TODO Pagination
        setFiles((await Storage.list('')).results);
    }

    const download = async (key) => {
        const result = await Storage.get(key);
        const downloadLink = document.createElement('a');
        downloadLink.href = result;
        downloadLink.download = key;
        downloadLink.click();
    }

    const uploadMarkup = isAdmin() ? (
        <>
            <Divider/>
            <h4>Upload a file</h4>
            <Button as="label" htmlFor="file" type="button">
                Choose file
            </Button>
            <input type="file" id="file" style={{display: "hidden", visibility: "hidden"}}
                   onChange={fileSelected}/>
            {uploadFile ? (
                <Message info>
                    <Message.Header>{uploadFile.name}</Message.Header>
                    <Button as="label" type="button" onClick={upload}>
                        Upload
                    </Button>
                </Message>
            ) : null}
        </>
    ) : null

    const showDelete = (key) => (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDeleteModalVisible(true)
        setDeleteFileKey(key)
    }

    const closeModal = () => {
        setDeleteModalVisible(false);
        setDeleteFileKey('');
    }
    const confirmDelete = async () => {
        console.log(`Deleting file with key: ${deleteFileKey}`);
        await Storage.remove(deleteFileKey)

        setFiles((await Storage.list('')).results);
        setDeleteModalVisible(false);
        setDeleteFileKey(null);
    }
    return (
        <Container>
            <Header as="h1">Documents</Header>
            <Modal
                size='mini'
                open={deleteModalVisible}
                onClose={closeModal}
            >
                <Header icon='trash' content='Confirm Delete' />
                <Modal.Content>
                    <p>Are you sure you want to delete this file?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={closeModal}>
                        No
                    </Button>
                    <Button onClick={confirmDelete}>
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
            <List>
                {files.map((file, idx) => {
                    const trash = isAdmin() ? <Icon style={{float: 'right'}} size="big" name="trash"
                                       onClick={showDelete(file.key)}/> : null;
                    return (
                        <List.Item key={file.key} style={{cursor: 'pointer'}} onClick={() => download(file.key)}>
                            <List.Content>
                                <Message icon size='mini'>
                                    <Icon name="download"/>
                                    <Message.Content>
                                        {file.key}
                                        {trash}
                                    </Message.Content>
                                </Message>
                            </List.Content>
                            {/*TODO admin delete button*/}
                        </List.Item>
                    );
                })}
            </List>
            {uploadMarkup}

        </Container>
    )
}

export default DocumentsPage