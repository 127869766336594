import React from 'react';
import menatwork from '../images/menatwork.png'
import {
    Container,
    Header,
    Image,
    Segment
} from 'semantic-ui-react';

const AdminPage = () => {


    return (
        <Container text textAlign='left'>
        <Segment basic>
            <Header as="h1">Admin</Header>
            <p style={{ fontSize: '1.33em' }}>
                Men at work. Check back later.
            </p>
            <Image alt="working hard" src={menatwork} />
        </Segment>
        </Container>
    );
};

export default AdminPage;
