import React, {useContext, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Container, Menu} from 'semantic-ui-react';
import {useNavigate} from 'react-router-dom';
import UserContext from "../contexts/UserContext";
import NotificationContext from "../contexts/NotificationContext";

const Navbar = () => {

    // TODO Hamburger menu on phones
    const navigate = useNavigate();
    const location = useLocation();
    const pathRoot = location.pathname.split('/')[1]
    const {isAdmin, user, logout} = useContext(UserContext);
    const {clearNotification} = useContext(NotificationContext)

    useEffect(() => {
        clearNotification()
    }, [location]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };


    return (
        <Menu inverted color={"blue"} borderless style={{borderRadius: 0}}>
            <Container>
                <Menu.Item as={Link} to="/" active={pathRoot === ''}>
                    Home
                </Menu.Item>
                <Menu.Item as={Link} to="/leaflet" active={pathRoot === 'leaflet'}>
                    Leaflet
                </Menu.Item>
                {user && (
                    <>
                        <Menu.Item as={Link} to="/forum" active={pathRoot === 'forum'}>
                            Forum
                        </Menu.Item>
                        <Menu.Item as={Link} to="/documents" active={pathRoot === 'documents'}>
                            Documents
                        </Menu.Item>
                        {isAdmin() ?
                            <Menu.Item as={Link} to="/admin" active={pathRoot === 'admin'}>
                                Admin
                            </Menu.Item>
                            :
                            <></>
                        }

                    </>
                )}
                {!user ? (
                    <Menu.Item as={Link} to="/login" active={pathRoot === 'login'}>
                        Login
                    </Menu.Item>
                ) : (
                    <Menu.Menu position="right">
                        <Menu.Item as={Link} to="/account" active={pathRoot === 'account'}>
                            Account
                        </Menu.Item>
                        <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
                    </Menu.Menu>
                )}
            </Container>
        </Menu>
    );
};

export default Navbar;
