import React, {useContext} from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from "../contexts/UserContext";

const ProtectedRoute = ({ children, redirectTo}) => {
    const { loading, user } = useContext(UserContext);

    if (loading) return null;

    return user ? children : (
        <Navigate to={redirectTo} />
    );
};

export default ProtectedRoute;
