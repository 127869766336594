import React, {useState} from 'react';
import {Button, Form, TextArea} from 'semantic-ui-react';
import forumService from "../services/forumService";

const NewReply = ({forumId, threadId, setNewReply}) => {

    const [replyBody, setReplyBody] = useState('');

    const newReply = async () => {
        setNewReply(await forumService.createThreadReply({forumId, threadId, replyBody, setNewReply}));
        setReplyBody('')
    }

    return (
        <Form>
            <h4>New reply</h4>
            <TextArea placeholder={'Body'} value={replyBody} onChange={(event) => setReplyBody(event.target.value)}/>
            <Button primary style={{margin: "1em 0 0 0"}}  onClick={() => newReply()}>Submit</Button>
        </Form>
    );
};

export default NewReply;
