import React, {useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Header, Button} from 'semantic-ui-react';
import UserContext from "../contexts/UserContext";

const HomePage = () => {

    const {load, user} = useContext(UserContext);

    useEffect(() => {
        load()
    }, [])

    return (
        <div>
            <Header as="h1">Welcome to the Ravelston Rise Residents' Association</Header>
            <p>
                Join our community and connect with your neighbours! Share your thoughts, ideas and get updates about events and
                important announcements.
            </p>

            {!user ? (
                <Button primary as={Link} to="/login">
                    Login
                </Button>
            ) : (
                <Button primary as={Link} to="/Account">
                    Account
                </Button>
            )}
        </div>
    );
};

export default HomePage;
