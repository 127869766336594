import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Breadcrumb} from "semantic-ui-react";

const MainBreadcrumb = () => {
    const [sections, setSections] = useState([
        {key: 'Home', content: 'Home', active: true}
    ]);
    const navigate = useNavigate();
    const location = useLocation()
    useEffect(() => {

        const path = location.pathname.split("/")
        switch (path[1]) {
            case '':
                setSections([{key: 'Home', content: 'Home', active: true}])
                break;
            case 'account':
                setSections([
                    {key: 'Home', content: 'Home', link: true, onClick: () => navigate('/')},
                    {key: 'Account', content: 'Account', active: true}
                ])
                break;
            case 'documents':
                setSections([
                    {key: 'Home', content: 'Home', link: true, onClick: () => navigate('/')},
                    {key: 'Documents', content: 'Documents', active: true}
                ])
                break;
            case 'forum':
                if (path[2]) {
                    setSections([
                        {key: 'Home', content: 'Home', link: true, onClick: () => navigate('/')},
                        {key: 'Forum', content: 'Forum', link: true, onClick: () => navigate('/forum')},
                        {key: 'Thread', content: 'Thread', active: true}
                    ])
                } else {
                    setSections([
                        {key: 'Home', content: 'Home', link: true, onClick: () => navigate('/')},
                        {key: 'Forum', content: 'Forum', active: true}
                    ])
                }
                break;
        }
        console.log(location)
    }, [location])
    return(<Breadcrumb icon='right angle' sections={sections}/>)
}

export default MainBreadcrumb;