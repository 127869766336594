import React, {useState} from 'react';
import NotificationContext from '../contexts/NotificationContext';

export const NotificationProvider = ({children}) => {
    const [style, setStyle] = useState("info");
    const [message, setMessage] = useState("")
    const [notificationVisible, setNotificationVisible] = useState(false)


    const clearNotification = () => {
        setMessage("")
        setStyle("info")
        setNotificationVisible(false)
    }

    const setNotification = (message, style = "info") => {
        setStyle(style)
        setMessage(message)
        setNotificationVisible(true)
        document.body.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <NotificationContext.Provider value={{style, message, notificationVisible, clearNotification, setNotification}}>
            {children}
        </NotificationContext.Provider>
    );
};
