import React from 'react';
import {Link} from 'react-router-dom';
import {List, Message} from 'semantic-ui-react';

const ThreadList = ({threads}) => {
    return (
        <div>
            <List divided relaxed>
                {threads.map(thread => (
                    <List.Item key={thread.id} className={'thread-list-item'}>
                        <List.Content>
                            <Link to={`/forum/thread/${thread.id}`}>
                                <Message
                                    size="small"
                                    icon='chevron right small'
                                    header={thread.title}
                                    content={"-" + thread.author}
                                />
                            </Link>
                                {/*TODO delete threads and replies*/}
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default ThreadList;
