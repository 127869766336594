import React, {useContext, useState} from 'react';
import {
    Button,
    Checkbox,
    Container,
    Divider,
    Form,
    Header,
    Icon,
    List,
    ListItem,
    Segment
} from 'semantic-ui-react';
import UserContext from "../contexts/UserContext";
import NotificationContext from "../contexts/NotificationContext";

const AccountPage = () => {

    const {user, updateAttributes, changePassword} = useContext(UserContext);
    const {setNotification} = useContext(NotificationContext)

    const [username] = useState(user.username)

    const [email, setEmail] = useState(user.attributes.email)
    const [gdprConsent, setGdprConsent] = useState(user.attributes['custom:gdpr_consent'] === 'true')
    const [forumEmailsConsent, setForumEmailsConsent] = useState(user.attributes['custom:forum_emails'] === 'true')

    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordWrong, setOldPasswordWrong] = useState(false)
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordWrong, setNewPasswordWrong] = useState(false);
    const [doubleEntry, setDoubleEntry] = useState('');
    const [doubleEntryWrong, setDoubleEntryWrong] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);

    const handleUpdateAccount = async (e) => {
        e.preventDefault();
        try {
            await updateAttributes({
                email: email,
                'custom:gdpr_consent': gdprConsent ? 'true' : 'false',
                'custom:forum_emails': forumEmailsConsent ? 'true' : 'false'
            })
            setNotification("Successfully updated account details", "info")
        } catch (error) {
            setNotification(`Failed to updated account details: ${error.message}`, "warning")
            console.error('Error updating user attributes:', error);
        }
    };
    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== doubleEntry) {
            setNotification('Passwords do not match.', "warning")
            setDoubleEntryWrong(true)
        } else {
            try {
                await changePassword(oldPassword, newPassword)
                setOldPassword('')
                setOldPasswordWrong(false)
                setNewPassword('')
                setNewPasswordWrong(false)
                setDoubleEntry('')
                setDoubleEntryWrong(false)
                setNotification("Successfully changed password", "info")
            } catch (error) {
                setNotification(`Failed to change password: ${error.message}`, "warning")
                if (error.code === "NotAuthorizedException") {
                    setOldPasswordWrong(true)
                }
                if (error.code === "InvalidParameterException") {
                    setNewPasswordWrong(true)
                }
                console.error('Error changing password:', error);
            }
        }
    };

    const oldPasswordChange = (e) => {
        setOldPassword(e.target.value)
        setOldPasswordWrong(false)
    }

    const doubleEntryChange = (e) => {
        setDoubleEntry(e.target.value)
        setDoubleEntryWrong(false)
    }

    const newPasswordChange = (e) => {
        setNewPassword(e.target.value)
        setNewPasswordWrong(false)
        setDoubleEntryWrong(false)
    }

    return (
        <Container text textAlign='left'>
        <Segment basic>
            <Header as="h1">Account Settings: {username}</Header>
            <Form onSubmit={handleUpdateAccount}>
                <Form.Field>
                    <label>Email (optional)</label>
                    <Form.Input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label="Opt in to receive emails with important Residents' Association notices."
                        checked={gdprConsent}
                        onChange={(e, data) => setGdprConsent(data.checked)}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label="Opt in to receive notification of new posts in the forum."
                        checked={forumEmailsConsent}
                        onChange={(e, data) => setForumEmailsConsent(data.checked)}
                    />
                </Form.Field>

                <Button type="submit" primary>
                    Update settings
                </Button>
            </Form>
            <Divider horizontal>-</Divider>

            <Header as="h1">Change password</Header>
            <p>New passwords must:</p>
            <List bulleted>
                <ListItem><p>Contain at least 8 characters</p></ListItem>
                <ListItem><p>Contain lowercase characters</p></ListItem>
                <ListItem><p>Contain numbers</p></ListItem>
                <ListItem><p>Contain special symbols (%,#,& etc)</p></ListItem>
                <ListItem><p>Contain upper case characters</p></ListItem>
            </List>
            <Form onSubmit={handleChangePassword}>
                    <Form.Field>
                        <label>Old Password</label>
                        <Form.Group inline>
                            <Form.Input
                                type={showPasswords ? "text" : "password"}
                                placeholder="Enter your old password"
                                value={oldPassword}
                                onChange={oldPasswordChange}
                                error={oldPasswordWrong ? {content: 'Your old password is incorrect'} : null }
                                width={15}
                            />
                            <Icon onClick={() => setShowPasswords(!showPasswords)} width={1} size='large' name={showPasswords ? 'eye slash' : 'eye'} />
                        </Form.Group>
                    </Form.Field>
                <Form.Field>
                    <label>New Password</label>
                    <Form.Group inline>
                        <Form.Input
                            type={showPasswords ? "text" : "password"}
                            placeholder="Enter your new password"
                            value={newPassword}
                            onChange={newPasswordChange}
                            error={newPasswordWrong ? {content: 'Your new password is invalid. See rules above.'} : null }
                            width={15}
                        />
                        <Icon onClick={() => setShowPasswords(!showPasswords)} width={1} size='large' name={showPasswords ? 'eye slash' : 'eye'} />
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <label>Enter new password again</label>
                    <Form.Group inline>
                        <Form.Input
                            type={showPasswords ? "text" : "password"}
                            placeholder="Enter your new password again"
                            value={doubleEntry}
                            onChange={doubleEntryChange}
                            error={doubleEntryWrong ? {content: 'Passwords do not match'} : null}
                            width={15}
                        />
                        <Icon onClick={() => setShowPasswords(!showPasswords)} width={1} size='large' name={showPasswords ? 'eye slash' : 'eye'} />
                    </Form.Group>
                </Form.Field>
                <Button type="submit" primary>
                    Update Password
                </Button>
            </Form>

        </Segment>
        </Container>
    );
};

export default AccountPage;
