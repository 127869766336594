import {Auth, config} from "../aws-config";

const apiGatewayEndpoint = config.apiGateway.invokeUrl

const getJwt = async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
}

const createThread = async ({forumId, threadTitle, threadBody}) => {
    const jwt = await getJwt();
    const response = await fetch(`${apiGatewayEndpoint}/threads`, {
        method: 'POST',
        headers: {
            "Token": jwt
        },
        body: JSON.stringify({
            forumId,
            threadTitle,
            threadBody
        })
    });
    const responseJson = await response.json()
    console.log("New thread ID:" + JSON.stringify(responseJson))
    return responseJson.threadId
}

const getThreads = async ({forumId,nextPaginationToken}) => {
    const jwt = await getJwt();
    const response = await fetch(`${apiGatewayEndpoint}/threads?forumId=${forumId}${nextPaginationToken ? "&paginationToken=" + nextPaginationToken : ""}`, {
        headers: {
            "Token": jwt
        }
    })
    const body = await response.json()
    return {
        threads: body.threads.map(result => ({
            author: result['authorId'],
            body: result['threadBody'],
            id: result['id'],
            title: result['threadTitle']
        })),
        nextPaginationToken: body.nextPaginationToken
    }
};

const getThreadReplies = async ({forumId, threadId}) => {
    const jwt = await getJwt();
    const response = await fetch(`${apiGatewayEndpoint}/replies?forumId=${forumId}&threadId=${threadId}`, {
        headers: {
            "Token": jwt
        }
    })
    const body = await response.json()
    const thread = body.thread
    const replies = body.replies
    return {thread, replies}
};


const createThreadReply = async ({forumId, threadId, replyBody}) => {
    const jwt = await getJwt();
    return await fetch(`${apiGatewayEndpoint}/replies`, {
        method: 'POST',
        headers: {
            "Token": jwt
        },
        body: JSON.stringify({
            forumId,
            threadId,
            replyBody
        })
    });
}

const exports = {
    createThreadReply,
    createThread,
    getThreads,
    getThreadReplies
};
export default exports;
