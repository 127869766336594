import React, {useState} from 'react';
import UserContext from '../contexts/UserContext';
import {Auth} from "../aws-config";
import jwtDecode from 'jwt-decode';

export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null); // You can replace null with data from a saved session
    const [loading, setLoading] = useState(true)

    const load = async () => {
        try {
            setUser(await Auth.currentAuthenticatedUser())
            setLoading(false)
        } catch (error) {
            console.warn("No current user")
        }
    }

    const login = async ({username, password}) => {

        try {
            const user = await Auth.signIn(username, password);
            console.log("User signed in",  user);

            setUser(user);
        } catch (error) {
            console.error('Error signing in', error);
            throw error;
        }

        return user;
    };

    const logout = async () => {
        await Auth.signOut();
        setUser(null);
    };

    const updateAttributes = async (attributes) => {
        await Auth.updateUserAttributes(user, attributes);
        await load();
    }
    const changePassword = async (oldPassword, newPassword) => {
        await Auth.changePassword(user, oldPassword, newPassword);
        await load();
    }

    const isAdmin = () => {
        try {
            const token = user.signInUserSession.idToken.jwtToken;
            const decodedToken = jwtDecode(token);
            const userGroups = decodedToken['cognito:groups'] || [];

            return userGroups.includes('Admins');
        } catch (error) {
            console.error('Error fetching user info', error);
            return false;
        }
    }

    return (
        <UserContext.Provider value={{isAdmin, load, loading, login, logout, updateAttributes, changePassword, user}}>
            {children}
        </UserContext.Provider>
    );
};
