import React, {useState} from 'react';
import {Button, Form, TextArea} from 'semantic-ui-react';
import {TextField} from "@aws-amplify/ui-react";
import forumService from "../services/forumService";
import {useNavigate} from "react-router-dom";

const NewThread = ({forumId, setNewThread}) => {

    const navigate = useNavigate();
    const [threadTitle, setThreadTitle] = useState('');
    const [threadBody, setThreadBody] = useState('');

    const newThread = async () => {
        const threadId = await forumService.createThread({forumId, threadBody, threadTitle})
        setNewThread(threadId)
        setThreadBody('')
        setThreadTitle('')
        navigate(`/forum/thread/${threadId}`)
    }

    return (
        <Form>
            <h4>New thread</h4>
            <TextField placeholder={'Title'} value={threadTitle} onChange={(event) => setThreadTitle(event.target.value)}/>
            <TextArea placeholder={'Body'} value={threadBody} onChange={(event) => setThreadBody(event.target.value)}/>
            <Button primary style={{margin: "1em 0 0 0"}} onClick={(event) => newThread()}>Submit</Button>
        </Form>
    );
};

export default NewThread;
