import React from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import reportWebVitals from "./reportWebVitals";
import {UserProvider} from "./providers/UserProvider";
import {NotificationProvider} from "./providers/NotificationProvider";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <UserProvider>
            <NotificationProvider>
                <App />
            </NotificationProvider>
        </UserProvider>
    </React.StrictMode>
);

reportWebVitals();