import React, {useContext, useEffect} from 'react';
import {
    Container,
    Divider,
    Header,
    List,
    ListItem,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableRow
} from 'semantic-ui-react';
import UserContext from "../contexts/UserContext";

const LeafletPage = () => {

    const {load, user} = useContext(UserContext);

    useEffect(() => {
        load()
    }, [])

    return (
        <Container text textAlign='left'>
            <Segment basic>
                <Header as="h1">Regulations and History</Header>
                <p>In 1978, the Council drew up a set of regulations, which largely represent a mutual agreement by the
                    Proprietors. The following is the current version:</p>

                <List ordered>
                    <ListItem><p><strong>
                        It is requested that all residents respect their neighbours with regard to noise,
                        particularly concerning televisions/radios, musical instruments, DIY/building work, and the
                        parking and garaging of cars.
                    </strong></p></ListItem>
                    <ListItem><p><strong>The use of the gardens by residents is encouraged with a few limitations.
                    </strong> There should be no cycling on the grass, no ball games should be played, and no items
                        such as chairs, toys, etc should be left out unattended. Camping in the grounds is not allowed.
                        Please be particularly careful not to leave any debris in the grass eg. sticks and stones that
                        could potentially damage grass cutting machinery. Please do not disturb other residents with
                        music.
                    </p></ListItem>
                    <ListItem><p><strong>
                        All gardening activities in the communal grounds should only be undertaken by the gardeners
                        employed by the Association, who act on the instruction of the Association’s Council.
                    </strong></p></ListItem>
                    <ListItem><p>
                        <strong>Dog owners</strong> are asked to prevent their pets from running free on the grassed
                        areas or fouling footpaths, paved entrances or any other aspect of the shared grounds. Please
                        ‘pick up’ after your dog in the interests of health & safety.
                    </p></ListItem>
                    <ListItem><p>
                        <strong>Washing</strong> and other clothes should not be hung visibly on flat balconies, nor in
                        any other part of the gardens.
                    </p></ListItem>
                    <ListItem><p>
                        <strong>The Association is not responsible for the upkeep of the flats.</strong> Each block
                        makes individual arrangements for stair/window cleaning, insurance, maintenance, etc. Each block
                        is also responsible for the raised flowerbed around its own conservatory entrance.
                    </p></ListItem>
                    <ListItem><p>
                        Flat owners should ensure that <strong>drainage holes</strong> in the balconies are kept clear.
                    </p></ListItem>
                    <ListItem><p>
                        <strong>Only private cars should be parked</strong> in the parking areas within the amenity.
                    </p></ListItem>
                    <ListItem><p>
                        <strong>It is strongly recommended that all residents make at least one neighbour aware of
                            one or two emergency telephone numbers.</strong> There have been problems encountered by
                        residents regarding water leaks, security alarms and the death of neighbours.
                    </p></ListItem>
                    <ListItem><p>
                        <strong>The large bin is intended for the use of properties without their own individual
                            wheelie bin, and landfill only. Please recycle other materials appropriately.</strong> If
                        possible, please store bins in your garage. The dumping of rubbish of any kind is particularly
                        prohibited. Binbags should not be left next to the communal bin as they will attract vermin.
                    </p></ListItem>
                    <ListItem><p>
                        <strong>External paintwork</strong> across the development is agreed as:
                        <Table celled>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Garage Doors</TableCell>
                                    <TableCell>Dark brown</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Porches</TableCell>
                                    <TableCell>Cedar Butinox Preservative</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>


                    </p></ListItem>
                </List>
                <p>In addition, we ask that owners keep the Association up-to-date with contact details. We keep your
                    data securely, solely for the purposes of administering the Association’s business, in accordance
                    with the General Data Protection Regulations. Finally, please let the Association know if you need
                    to have cables laid across the grounds (e.g. for television/internet) so a wayleave can be prepared.
                    RAVELSTON RISE LIMITED
                </p>
                <p>Each property owner here automatically becomes a member of the Proprietors’ Association, which is a
                    Company limited by Guarantee. The Association’s principal responsibility is the maintenance of the
                    communal gardens on behalf of all the owners and residents, and it places a contract directly with
                    professional gardeners for this work.
                </p>
                <p>The Association has an unpaid, volunteer Council. There is no ‘factor’ involved, in the interests of
                    minimising costs.
                </p>
                <p>We organise an annual meeting for owners at which we present updates and plans, and are always happy
                    to receive suggestions at other times.
                </p>
                <p>In the meantime, we hope that all residents will enjoy the gardens, which form one of the principal
                    attractions of this development.
                </p>

                <Divider horizontal>History</Divider>

                <p>The first plans for building on the land between Craigleith Avenue South and the houses on Ravelston
                    Dykes were drawn up in the late 1930s, as a second phase of the Ravelston Garden project. These
                    plans were abandoned during World War Two, and it was only in the late 1970s, after several
                    unexecuted schemes had been proposed, that Miller Homes (Northern) built the 57 houses and flats
                    which form the Ravelston Rise development.</p>
                <p>The Association was formed by the first proprietors as a result of the clauses inserted into our
                    title deeds by Miller. Those clauses required the proprietors to form an association for the upkeep
                    of the communal garden ground and other communal amenity areas. They also require each proprietor to
                    contribute a proportional share of the cost of maintaining “all parts common and mutual”. A small
                    number of simple regulations were agreed, and are printed overleaf.</p>
                <p>The Association holds an AGM each year in April and hopes that someone from each property will
                    attend. These occasions are a good opportunity to meet neighbours as well as the Council members.
                    The Council is elected at these meetings and plans for the coming year are presented.</p>

                <p>The Council is made up of volunteers, usually elected for a three-year term. We meet three times a
                    year, in addition to the AGM, and hope that all owners will at some stage offer to join – membership
                    is not at all onerous, and does not require any specialist gardening knowledge! The Council works
                    best with regular new members and new ideas.</p>
                <p>Should you wish to get in touch, please write to the Chairman</p>

                <h3>The annual levy</h3>
                <p>The Council try very hard to work on the lowest possible budget and to keep the annual levy modest.
                    The amount is proposed by the Council and agreed at each AGM. We appreciate prompt payment when the
                    levy request is issued in March.</p>
                <h3>Current projects</h3>
                <p>There has been much work in recent years to renew the planting in the beds, and new semi-circular
                    beds have been made in front of the flats. The ‘rotunda’ at the centre of Ravelston Rise had become
                    overgrown and was home to foxes, so this has been re-landscaped, with ivy now being planted to grow
                    over the damaged brickwork. We have organised the removal of trees planted in the 1970s which had
                    grown too large. New trees have been planted where appropriate but we have tried to maintain a more
                    open outlook. New planting is selected by our gardeners for year-round interest. A wildflower meadow
                    in the east garden was a new addition in 2018. In the coming years, we plan a continued programme of
                    rolling maintenance and renewal, across the whole of the property.</p>
            </Segment>
        </Container>
    );
};

export default LeafletPage;
